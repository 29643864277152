import * as QRCode from "qrcode";

const params = new URLSearchParams(window.location.search);

const url = params.get('url');
const text = params.get('text');
const title = params.get('title');

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register(
        new URL('service-worker.js', import.meta.url),
        {type: 'module'}
    );
}

const qr = document.getElementById('qr');

async function generateCode(data, hide) {
    const canvas = document.getElementById('qr');
    try {
        const svgText = await QRCode.toString(data, {type: 'svg'});
        const placeholder = document.createElement('div');

        placeholder.innerHTML = svgText;
        const svg = placeholder.firstChild;
        qr.innerHTML ='';
        qr.appendChild(svg);
        params.set('text', data);
        window.history.replaceState({}, '', '?' + params.toString());
        if (hide) {
            document.geElementById('empty-page-content').style.display = 'none';
        }
        document.querySelector('header').innerText = title;
    } catch (e) {

    }
}

const value = url || text || title;

console.log("Params received:");
console.log("title:", title);
console.log("text:", text);
console.log("url:", url);

const input = document.getElementById('text');
input.addEventListener('input', (event) => {
    const text = event.target.value;
    generateCode(text, false);
});

if (value) {
    generateCode(value, true);
}


